export default function Description() {
  return (
    <div className="videos">
      <div className="video-container"> 
        <iframe src="https://www.youtube.com/embed/oiBDuY_LyFg" title="Working From Home" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>

      <div className="video-container"> 
        <iframe src="https://www.youtube.com/embed/qqqQ9SMOBto" title="Filter Face" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </div>
  )
}
