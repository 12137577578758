import {
	Vector2
} from "three";

/**
 * based on the Dot screen shader
 * based on glfx.js sepia shader
 * https://github.com/evanw/glfx.js
 */

const BGShader = {

	uniforms: {

		'resolution': { value: new Vector2( 256, 256 ) },
		'mouse': { value: new Vector2( 0, 0) },
		'time': { value: 20},
		'nightmode': { value: true}

	},

	vertexShader: /* glsl */`

		varying vec2 vUv;

		void main() {

			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

		}`,

	fragmentShader: /* glsl */`

		uniform float time;
		uniform vec2 mouse;
		uniform vec2 resolution;
		uniform bool nightmode;

		uniform sampler2D tDiffuse;

		varying vec2 vUv;

		void main() {

			//vec4 color = texture2D( tDiffuse, vUv );
			// gl_FragColor = vec4( vec3(0.1, 0.1, 0.1 ), 1.0 );

			float noise = (fract(sin(dot(vUv, vec2(12.9898,78.233)*2.0*time)) * 43758.5453)) * 0.3;

			vec2 position = ( gl_FragCoord.xy / resolution.xy ) + mouse / 4.0;

			float color = 0.0;
			color += sin( position.x * cos( time / 15.0 ) * 80.0 ) + cos( position.y * cos( time / 15.0 ) * 10.0 );
			color += sin( position.y * sin( time / 17.0 ) * 40.0 ) + cos( position.x * sin( time / 25.0 ) * 40.0 );
			color += sin( position.x * sin( time / 5.0 ) * 10.0 ) + sin( position.y * sin( time / 35.0 ) * 80.0 );
			color *= sin( time / 10.0 ) * 0.1;

			gl_FragColor = vec4( vec3( color, color * 0.5, sin( color + time / 3.0 ) * 0.75 ), 1.0 );
			color = nightmode ? gl_FragColor.r : (1.0 - gl_FragColor.r);

			color -= noise;

			gl_FragColor.r = gl_FragColor.g = gl_FragColor.b = color;

		}`

};

export { BGShader };