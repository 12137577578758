import eventBus from "./EventBus";
import { Component } from 'react'

class Header extends Component {

  constructor(props){
    super(props);
    this.state = {nightMode: false};
  }
  
  onNightModeToggle(e) {

    this.setState({
      nightMode: !this.state.nightMode
    });

    eventBus.dispatch("nightmode", this.state.nightMode)
  }

  render() {
    return (
      <div className="header">
        <h1 className="title"> 
          J O P O R T U S
        </h1>
        <button className="header__right-item" onClick={this.onNightModeToggle.bind(this)}>
          {this.state.nightMode 
            ? <i className="fa fa-moon-o" aria-hidden="true"></i>
            : <i className="fa fa-sun-o" aria-hidden="true"></i>
          }
        </button>
      </div>
    )
  }
}
export default Header;