import { useGLTF } from '@react-three/drei'
import { forwardRef, useMemo, useState, useRef } from 'react'
import { useLoader, useFrame} from "@react-three/fiber"
import * as THREE from "three";
import url from "./face.mp4";
import  './TrackMaterial';
import img1 from './img/rainbow.png';

export default forwardRef((props, ref) => {
  const { scene, materials } = useGLTF(process.env.PUBLIC_URL + '/jp2.gltf')
  
  const trackMatRef = useRef();
  const trackRef = useRef(); 

  const [texture1] = useLoader(THREE.TextureLoader, [img1]);
  
  const water = scene.children[0].children[0].children[0];
  const jp = scene.children[0].children[0].children[1];
  const rim = scene.children[0].children[0].children[2];
  const bg = scene.children[0].children[0].children[3];
  
  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.setAttribute('playsinline', '');
    
    vid.crossOrigin = "anonymous";
    vid.autoplay = true;
    vid.loop = true;
    vid.muted = true;
    vid.src = url;
    // vid.play();
    return vid;
  });

  var isDragging = false;
  var clock = new THREE.Clock();
  var downAngle;
  var dragAngle = 0;
  var spinAngle = 0;
  const spin_speed = 3;

  window.addEventListener("click", (e)=>{
    video.play();
  })
  

  useFrame(() => {
    if(!isDragging){
      spinAngle -= clock.getDelta() * spin_speed;      
    }

    trackRef.current.rotation.y = spinAngle - dragAngle;
    trackMatRef.current.u_time += clock.getDelta();
  })

  const calculateAngle = function(uv) {

    var delta_x = uv.x - 0.5
    var delta_y = uv.y - 0.5
    var theta_radians = Math.atan2(delta_y, delta_x);
    // console.log(theta_radians);

    return theta_radians;
  } 

  const onPointerDown = (event) => {
    event.stopPropagation();
    console.log(event);
    isDragging = true;
    downAngle = calculateAngle(event.uv);
    // console.log(downAngle);
  }

  const onPointerMove = (event) => {
    event.stopPropagation();
    if(isDragging){
      var angle = calculateAngle(event.uv);;
      dragAngle = downAngle - angle;
    }
  }

  const onPointerUp = (event) => {
    event.stopPropagation();
    isDragging = false;
  }

  const onPointerIn = (event) => {
    // event.stopPropagation();
    isDragging = true;
  }

  const onPointerOut = (event) => {
    // event.stopPropagation();
    isDragging = false;
  }
  
  return <group onPointerDown={onPointerDown} onPointerUp={onPointerUp} onPointerMove={onPointerMove} onPointerIn={onPointerIn} onPointerOut={onPointerOut}>
  <mesh geometry={bg.geometry}>
    <meshStandardMaterial emissive={"white"} side={THREE.DoubleSide}>
      <videoTexture attach="map" args={[video]} />
      <videoTexture attach="emissiveMap" args={[video]} />
    </meshStandardMaterial>
  </mesh>
  
  <mesh ref={trackRef} geometry={jp.geometry} rotation={[0,0,0]} >
    <trackMaterial ref={trackMatRef} tex={texture1} depthTest={false} />
  </mesh>
  
  <primitive ref={ref} object={rim} {...props} />
  </group>
})
