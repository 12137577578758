import React from 'react'
import ReactDOM from 'react-dom'
import { setup } from 'twind/shim'
import * as colors from 'twind/colors'
import './styles.css'

import Header from './Header'
import Footer from './Footer'
import App from './App'
import Description from './Description'
import Background from './Background'

import eventBus from "./EventBus";

setup({
  theme: {
    extend: { colors },
  },
})

function init() {
  eventBus.on("nightmode", (isNightmode) => {
    if(isNightmode) {
      document.getElementById('root').classList.add("nightmode");
    }else{
      document.getElementById('root').classList.remove("nightmode");
    }
  });
}

init();
ReactDOM.render(
  <>
    <div className="bg-container">
      <Background/>
    </div>

    <Header/>

    <div className="site-container">
      <div className="app-container">
        <App />
      </div>
      <div className="info-container">
        <h1><span className="jo">Jo</span><br/>Portus</h1>
        <p>
        Hi! I'm an award-winning Interactive Developer / Creative Technologist / Technical Artist with a speciality in Augmented Reality (AR) from Manchester, UK. My work spans from Instagram filters to site-specific
AR interventions, and clients range from major brands to groundbreaking artists and interactive theatre creators. I have over 10 years of professional development experience across multiple languages and platforms. I’m passionate about working at the cutting edge of innovative technology.
        </p>
      </div>
      <Description />
    </div>

    <Footer/>

  </>,
  document.getElementById('root')
)
