import { Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import Model from './Model'
import { useMVP } from './useMVP'
import { state, useSnapshot } from './state'

export default function App() {  

  return (
    <Canvas camera={{fov:7, position: [0, 100, 0] }}>
      <Suspense fallback={null}>
        <Scene  />
      </Suspense>
    </Canvas>
  )
}

function Scene() {
  const snap = useSnapshot(state)
  const { MVPScene, modelRef, cameraRef } = useMVP({ ...snap.transforms })

  return (
    <MVPScene  >
      <group ref={modelRef} position={[0,0,0]} rotation={[0,0,0]}>
        <ambientLight intensity={0.5} />
        <pointLight position={[1, 2, 3]} intensity={0.5} />
        <pointLight position={[1, 2, -3]} intensity={0.5} />
        <Model position={[0, 0, 0]} rotation={[0, 0, 0]}  />
      </group>
    </MVPScene>
  )
}
