import * as THREE from "three"
import { extend } from "@react-three/fiber"

export class TrackMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      uniforms: {
        effectFactor: { value: 0.0 },
        tex: { value: undefined },
        u_time: { value: 0.0 },
      },
      vertexShader: `varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }`,
      fragmentShader: `varying vec2 vUv;
      uniform sampler2D tex;
      uniform float effectFactor;
      uniform float u_time; 

      // All components are in the range [0…1], including hue.
      vec3 hsv2rgb(vec3 c)
      {
        vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
        vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
        return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
      }

      void main() {
        vec2 uv = vUv;

        //mask stuff
        float uvx = mod(uv.x - 0.128, 1.0);
        float x = 1.0 - step(0.2, uvx);
        vec4 black = vec4(0,0,0,1);

        float modX = mod(uv.x + u_time*0.3, 1.0);
        vec2 uv2 = vec2( modX , uv.y);
        // vec4 c = texture2D(tex, uv2);
        vec3 h = vec3(uv2.x, 1.0, 1.0);
        vec3 c1 = hsv2rgb(h);
        vec4 c = vec4(c1.r, c1.g, c1.b, 1.0-x*0.8);

        vec4 finalTexture = c;
        gl_FragColor = finalTexture;
      }`
    })

    this.transparent = true;
  }

  get effectFactor() {
    return this.uniforms.effectFactor.value
  }
  set effectFactor(v) {
    return (this.uniforms.effectFactor.value = v)
  }

  get tex() {
    return this.uniforms.tex.value
  }
  set tex(v) {
    return (this.uniforms.tex.value = v)
  }

  get u_time() {
    return this.uniforms.u_time.value
  }
  set u_time(v) {
    return (this.uniforms.u_time.value = v)
  }

}

extend({ TrackMaterial })
