import { useLayoutEffect, useMemo, useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { useHelper } from '@react-three/drei'
import { useSpring } from '@react-spring/three'
import * as THREE from 'three'

export function useMVP({ model, view, projection, screen }) {
  const modelRef = useRef()
  const cameraRef = useRef()
  const sceneRef = useRef()

  const MVPScene = ({ children }) => <group matrixAutoUpdate={false} ref={sceneRef} children={children} />
  return { MVPScene, modelRef, cameraRef }
}

