  export default function Footer() {
    return (
      <div className="footer">  
        <a href="https://www.facebook.com/jo.portus/" target="_blank" className="fa fa-facebook"></a>
        <a href="https://twitter.com/joportus/" target="_blank" className="fa fa-twitter"></a>
        <a href="https://www.linkedin.com/in/jo-portus/" target="_blank" className="fa fa-linkedin"></a>
        <a href="https://www.instagram.com/jo.portus/" target="_blank" className="fa fa-instagram"></a>
      </div>
    )
  }
  