import { ShaderMaterial, UniformsUtils } from "three";
import { Pass, FullScreenQuad } from "three/examples/jsm/postprocessing/Pass";
import { BGShader } from './BGShader';
import eventBus from "./EventBus";

class BGPass extends Pass {

	constructor( ) {

		super();

		if ( BGShader === undefined ) console.error( 'BGPass relies on BGShader' );

		var shader = BGShader;

		this.uniforms = UniformsUtils.clone( shader.uniforms );
		this.material = new ShaderMaterial( {

			uniforms: this.uniforms,
			vertexShader: shader.vertexShader,
			fragmentShader: shader.fragmentShader

		} );

		this.fsQuad = new FullScreenQuad( this.material );

		let handleMousemove = (event) => {
			
			const xmouse = event.x/1000;
			const ymouse = event.y/1000;

			this.mouse.x += (xmouse - this.mouse.x) * 0.05;
			this.mouse.y += (ymouse - this.mouse.y) * 0.05;

		  };
		this.mouse = {x: 0, y: 0};
		
		let handleNightmode = (isNightMode) => {
			this.uniforms[ 'nightmode' ].value = isNightMode;
		}

		document.addEventListener('mousemove', handleMousemove);

		eventBus.on('nightmode',handleNightmode );
	}

	render( renderer, writeBuffer, readBuffer, deltaTime, /* maskActive */ ) {

		this.uniforms[ 'time' ].value += deltaTime;
		this.uniforms[ 'resolution' ].value.set( readBuffer.width, readBuffer.height );
		this.uniforms[ 'mouse' ].value.set( this.mouse.x, this.mouse.y );

		if ( this.renderToScreen ) {

			renderer.setRenderTarget( null );
			this.fsQuad.render( renderer );

		} else {

			renderer.setRenderTarget( writeBuffer );
			if ( this.clear ) renderer.clear();
			this.fsQuad.render( renderer );
            
		}
	}
}

export { BGPass };