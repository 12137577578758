import { Canvas, extend, useFrame } from '@react-three/fiber'
import { Effects } from '@react-three/drei'
import { BGPass } from './BGPass';

extend({ BGPass });

export default function Background() {  

  return (
    <Canvas>

      <Effects>
        <bGPass attachArray="passes"  />
      </Effects>

    </Canvas>
  )
}
